<template>
  <div
    class="absolute shadow z-30 bottom-5 top-5 left-5 bg-white grid grid-cols-2 pr-6 pl-6 pt-5 grid-rows-layout reports ml-5 rounded-xl"
    style="width: 444px; left: 68px"
  >
    <div class="w-full flex">
      <span
        class="col-start-1 col-end-2 font-bold text-darkblue text-xl font-SourceSansPro"
      >
        {{ $t('reports') }}
      </span>
      <div
        class="flex items-center ml-8 cursor-pointer"
        @click="$emit('to-monitoring')"
      >
        <MapPointIcon />
        <span
          class="col-start-1 col-end-2 font-semibold text-darkblue text-l font-SourceSansPro ml-2"
        >
          {{ $t('monitoring') }}
        </span>
      </div>
    </div>
    <div class="flex justify-end col-start-2 items-center">
      <i
        class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold"
        @click="handleClose"
      />
    </div>
    <!-- container -->
    <div class="flex flex-col col-start-1 col-end-3">
      <!-- template select -->
      <div class="w-full flex items-center mt-8">
        <span
          class="blue-text font-SourceSansPro text-sm font-semibold mr-2 mt-2"
        >
          {{ $t('reports.select-template') }}
        </span>
        <el-select
          class="mt-3 flex-1"
          ref="templatesSelect"
          v-model="reportForm.tableType"
          @change="tableTypeChange"
        >
          <div
            class="flex justify-between items-center px-5 cursor-pointer"
            style="height: 34px; line-height: 34px"
            @click="
              $refs['templatesSelect'].blur()
              $emit('create-reporttemplate')
            "
          >
            <span
              class="blue-text font-SourceSansPro font-semibold"
              style="font-size: 13px"
            >
              {{ $t('reports.add-new-template') }}
            </span>
            <AddIcon />
          </div>
          <el-option
            v-for="(template, index) in reportTemplates"
            :key="index"
            :label="template.name"
            :value="template.id"
            v-if="shouldShowOption(template)"
          >
            <div class="flex justify-between items-center">
              <span
                class="font-bold text-headerText"
                :class="
                  template.id === reportForm.tableType
                    ? 'text-headerText'
                    : 'blue-text'
                "
              >
                {{ template.name }}
              </span>
              <span>
                <span
                  v-if="!template.is_default"
                  class="mr-4"
                  @click.stop="
                    $refs['templatesSelect'].blur()
                    $emit('update-reporttemplate', template)
                  "
                >
                  <EditIcon />
                </span>
                <span
                  v-if="!template.is_default"
                  @click.stop="openDialogDeleteTemplate(template)"
                >
                  <!-- <span @click.stop="centerDialogVisible = true"> -->
                  <CancelBigIcon />
                </span>
              </span>
            </div>
          </el-option>
        </el-select>
      </div>
      <!-- object group or from monitoring select -->
      <div
        class="w-full blue-text flex items-center mt-6 bg-select-object select-object-container"
      >
        <div class="flex justify-between w-16 mr-4">
          <skif-radio
            v-model="reportForm.object"
            reverse
            @change="objectChange"
            name="object"
            label="base"
          >
            {{ $t('reports.object') }}
          </skif-radio>
        </div>
        <div class="flex justify-between w-32">
          <skif-radio
            v-model="reportForm.object"
            @change="objectChange"
            reverse
            name="object"
            label="group"
          >
            {{ $t('reports.group-object') }}
          </skif-radio>
        </div>
        <div v-if="isFilterExist" class="flex justify-between ml-4 w-32">
          <skif-radio
            v-model="reportForm.object"
            @change="objectChange"
            name="object"
            label="monitoring"
            class="flex flex-row-reverse"
          >
            {{ $t('reports.from_monitoring') }}
          </skif-radio>
        </div>
      </div>
      <!--   select object  -->
      <div
        class="flex flex-row relative mt-4"
        v-if="reportForm.object !== 'monitoring'"
      >
        <span
          class="blue-text font-SourceSansPro text-sm font-semibold mt-4 mr-2"
        >
          {{ $t('reports.object') }} :
        </span>
        <div class="flex items-center flex-1">
          <el-select
            v-model="reportForm.unitsId"
            :default-first-option="true"
            :fit-input-width="true"
            :class="{ 'input-error': isUnitOrUnitsgroupMissing }"
            class="mt-3 w-full"
            filterable="filterable"
            ref="unitsSelect"
            @change="unitsNameChange"
          >
            <template v-if="reportForm.object === 'base'">
              <el-option
                class="text-lightblue"
                v-for="unit in sortedUnits"
                :key="unit.id"
                :label="unit.name"
                :value="unit.id"
              >
                <div class="flex justify-between">
                  <span class="truncate" style="width: 220px">
                    {{ unit.name }}
                  </span>
                  <span
                    @click="
                      $emit('update-unit', {
                        unitId: unit.id,
                        fromModule: 'reports'
                      })
                    "
                  >
                    <svg
                      width="14"
                      height="14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13.414.586a2 2 0 010 2.828l-2 2-2.828-2.828 2-2a2 2 0 012.828 0zM2.828 14l7.086-7.086-2.828-2.828L0 11.172V14h2.828z"
                        fill="#5477A9"
                      />
                    </svg>
                  </span>
                </div>
              </el-option>
            </template>
            <template v-else>
              <el-option
                class="text-lightblue"
                v-for="group in unitGroups"
                :key="group.id"
                :label="group.name"
                :value="group.id"
              >
                <div class="flex justify-between">
                  <span class="group-name"
                    >{{ group.name }} ({{ group.units.length }})</span
                  >
                  <span
                    @click="
                      $emit('update-unitsgroup', {
                        unitsgroup: group,
                        isUnits: false
                      })
                    "
                  >
                    <svg
                      width="14"
                      height="14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13.414.586a2 2 0 010 2.828l-2 2-2.828-2.828 2-2a2 2 0 012.828 0zM2.828 14l7.086-7.086-2.828-2.828L0 11.172V14h2.828z"
                        fill="#5477A9"
                      />
                    </svg>
                  </span>
                </div>
              </el-option>
            </template>
          </el-select>
          <div class="flex relative cursor-pointer" v-if="isOnlyUnits">
            <svg
              class="mt-2 ml-4"
              @click.stop="isShowAllUnits = !isShowAllUnits"
              width="20"
              height="20"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                :fill="!showAllUnits ? '#F56C6C' : '#5477A9'"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.3446 7.18139C13.3196 7.18139 13.2957 7.17718 13.2715 7.17467L4.64671 7.18287V7.18036C4.28874 7.17592 4 6.91396 4 6.59065C4 6.26437 4.29341 6 4.65558 6C4.66227 6 4.66846 6.00148 4.67516 6.00182H13.3248C13.3315 6.00171 13.3377 6 13.3444 6C13.7066 6 14 6.26435 14 6.59065C14.0001 6.91692 13.7067 7.18139 13.3446 7.18139ZM6.17718 8.74201C6.18387 8.74201 6.19007 8.74349 6.19676 8.74383H11.8031C11.8098 8.74349 11.816 8.74201 11.8227 8.74201C12.1848 8.74201 12.4783 9.00636 12.4783 9.33265C12.4783 9.65893 12.1848 9.9233 11.8227 9.9233C11.7977 9.9233 11.7738 9.9192 11.7495 9.91659L6.16809 9.9249V9.92239C5.81013 9.91795 5.52138 9.65599 5.52138 9.33256C5.52138 9.00649 5.81526 8.74201 6.17718 8.74201ZM7.9163 11.4839C7.923 11.4839 7.92919 11.4855 7.93589 11.4857H10.0642C10.0709 11.4856 10.0771 11.4839 10.0838 11.4839C10.4459 11.4839 10.7394 11.7485 10.7394 12.0745C10.7394 12.4008 10.4459 12.6652 10.0838 12.6652C10.0588 12.6652 10.0349 12.661 10.0106 12.6585L7.90744 12.6667V12.6642C7.54947 12.6597 7.26073 12.3978 7.26073 12.0743C7.26073 11.7485 7.55426 11.4839 7.9163 11.4839Z"
                :fill="!showAllUnits ? '#F56C6C' : '#5477A9'"
              />
            </svg>
          </div>
          <transition name="slide-fade" mode="out-in">
            <div
              class="absolute bg-white px-2 py-4 pl-3 tooltip-warning"
              v-if="isShowAllUnits"
            >
              <div
                class="text-darkblue font-SourceSansPro text-sm font-semibold"
              >
                <div class="flex mb-2">
                  <svg
                    class="mt-2 mr-4"
                    width="20"
                    height="20"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                      fill="#5477A9"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.3446 7.18139C13.3196 7.18139 13.2957 7.17718 13.2715 7.17467L4.64671 7.18287V7.18036C4.28874 7.17592 4 6.91396 4 6.59065C4 6.26437 4.29341 6 4.65558 6C4.66227 6 4.66846 6.00148 4.67516 6.00182H13.3248C13.3315 6.00171 13.3377 6 13.3444 6C13.7066 6 14 6.26435 14 6.59065C14.0001 6.91692 13.7067 7.18139 13.3446 7.18139ZM6.17718 8.74201C6.18387 8.74201 6.19007 8.74349 6.19676 8.74383H11.8031C11.8098 8.74349 11.816 8.74201 11.8227 8.74201C12.1848 8.74201 12.4783 9.00636 12.4783 9.33265C12.4783 9.65893 12.1848 9.9233 11.8227 9.9233C11.7977 9.9233 11.7738 9.9192 11.7495 9.91659L6.16809 9.9249V9.92239C5.81013 9.91795 5.52138 9.65599 5.52138 9.33256C5.52138 9.00649 5.81526 8.74201 6.17718 8.74201ZM7.9163 11.4839C7.923 11.4839 7.92919 11.4855 7.93589 11.4857H10.0642C10.0709 11.4856 10.0771 11.4839 10.0838 11.4839C10.4459 11.4839 10.7394 11.7485 10.7394 12.0745C10.7394 12.4008 10.4459 12.6652 10.0838 12.6652C10.0588 12.6652 10.0349 12.661 10.0106 12.6585L7.90744 12.6667V12.6642C7.54947 12.6597 7.26073 12.3978 7.26073 12.0743C7.26073 11.7485 7.55426 11.4839 7.9163 11.4839Z"
                      fill="#5477A9"
                    />
                  </svg>
                  <div class="flex flex-col">
                    <span>{{ $t('reports.applied_filter') }}</span>
                    <span>{{ $t('reports.not_all_objects_displayed') }}</span>
                  </div>
                </div>
                <div class="flex items-center">
                  <skif-checkbox
                    class="mr-4 ml-1"
                    v-model="showAllUnits"
                    @change="toggleAllUnits"
                  />
                  <span
                    class="text-darkblue font-SourceSansPro text-sm font-semibold"
                  >
                    {{ $t('reports.show_all_objects') }}
                  </span>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <transition name="el-zoom-in-top">
          <div
            class="text-notify text-xs top-full left-0 absolute"
            v-if="isUnitOrUnitsgroupMissing"
          >
            {{
              reportForm.object === 'base' || reportForm.object === 'monitoring'
                ? $t('reports.select_object')
                : $('reports.select_objects_group')
            }}
          </div>
        </transition>
      </div>
      <!-- Period -->
      <div class="w-full flex items-center mt-8">
        <span class="blue-text font-SourceSansPro text-sm font-semibold mr-2">
          {{ $t('reports.period') }} :
        </span>
        <el-date-picker
          ref="datePickerRef"
          class="flex-1"
          style="width: 100%"
          v-model="reportForm.datePicker"
          popper-class="reportsDatepicker"
          type="datetimerange"
          range-separator="-"
          value-format="yyyy-MM-dd HH:mm:ss"
          :firstDayOfWeek="1"
          :clearable="false"
          :start-placeholder="$t('reports.not-select')"
          :end-placeholder="$t('reports.not-select')"
          :picker-options="pickerOptions"
          :format="dateFormat + ' HH:mm:ss'"
          :default-time="defaultTimeValues"
          @paste.native="onPaste"
          @focus="handlerFocus"
          @change="dateChange"
        />
        <el-popover
          v-if="isPeriodSet"
          placement="bottom"
          width="160"
          trigger="hover"
          class="ml-2 p-0"
          popper-class="info-popoverlay"
        >
          <div class="info-container">
            <span>Применён период из отчета. Вы можете изменить его .</span>
          </div>

          <div slot="reference">
            <InfoIconBlue />
          </div>
        </el-popover>
      </div>
      <div class="period flex ml-16 mt-2 justify-between mr-1">
        <div @click="setPeriod('today')" class="period-item">
          <span>Сегодня</span>
        </div>
        <div @click="setPeriod('yesterday')" class="period-item">
          <span>Вчера</span>
        </div>
        <div @click="setPeriod('this_week')" class="period-item">
          <span>Эта неделя</span>
        </div>
        <div @click="setPeriod('this_month')" class="period-item">
          <span>Этот месяц</span>
        </div>
      </div>
      <el-dialog
        :modalAppendToBody="false"
        append-to-body
        :title="$t('reports.template_deletion')"
        custom-class="delete_template"
        center="center"
        width="40%"
        :visible.sync="centerDialogVisible"
      >
        <div
          class="mb-4"
          style="color: #5477a9; white-space: break-spaces; text-align: center"
        >
          {{ $t('reports.template_deletion_sure') }}
          <b class="boldText"> {{ objectDeleteTemplate.name }} </b> ?
        </div>
        <span
          class="dialog-footer"
          style="display: flex; justify-content: center"
        >
          <span
            class="mr-4 cursor-pointer"
            @click="centerDialogVisible = false"
          >
            {{ $t('cancel') }}
          </span>
          <skif-button
            class="btn cursor-pointer"
            @click.stop="deleteTemplate(objectDeleteTemplate)"
          >
            {{ $t('delete') }}
          </skif-button>
        </span>
      </el-dialog>
    </div>

    <div
      class="col-start-1 col-end-3 overflow-y-auto my-2 flex flex-col w-full"
    >
      <div
        class="flex w-full items-center justify-between sticky top-0 bg-white z-10"
      >
        <div class="pb-2 z-10 flex bg-white">
          <skif-checkbox
            class="p-2"
            v-model="printAllTables"
            @change="togglePrintAll"
            :indeterminate="printIndeterminate"
            :disabled="!tablesForTemplate.length"
          />
          <div
            class="pt-2 bg-white font-bold text-darkblue text-lg font-SourceSansPro"
          >
            {{ $t('reports.tables') }}
          </div>
        </div>
        <div
          class="pb-2 z-10 flex font-bold text-darkblue text-lg font-SourceSansPro"
        >
          <el-dropdown
            :disabled="!printLink"
            trigger="click"
            @command="downloadAllReports"
          >
            <div
              class="skif-load-link"
              :class="[
                { disabled: !printLink },
                { 'cursor-pointer': printLink }
              ]"
            >
              <span> {{ $t('reports.download_selected') }} </span>
              <LoadIcon :print-link="printLink" />
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="excel"
                  >{{ $t('save') }} Excel</el-dropdown-item
                >
                <el-dropdown-item command="word"
                  >{{ $t('save') }} Word</el-dropdown-item
                >
                <el-dropdown-item command="pdf"
                  >{{ $t('save') }} PDF</el-dropdown-item
                >
                <el-dropdown-item command="composed">
                  {{ $t('reports.сomposite_report') }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>

      <!-- search section -->
      <div
        class="mb-2 pb-2 flex flex-col justify-center items-center searching-container bg-white z-10"
      >
        <skif-search
          v-model="reportString"
          :placeholder="'Поиск по таблицам'"
          class="w-full"
          @searching="searching"
        />
      </div>
      <table class="w-full">
        <tbody class="border-t">
          <tr
            class="cursor-pointer lighter-border-b hover_bg-reallylightblue"
            v-for="(table, index) in filteredTablesForTemplate"
            :key="index"
            @click="showReport(table)"
          >
            <td class="w-3">
              <skif-checkbox
                v-if="!table.is_separately"
                class="p-2"
                v-model="printTablesObj[table.id]"
                @click.native.stop
                @change="togglePrint(table.id, $event)"
              />
            </td>
            <td>
              <div class="flex justify-between items-center">
                <span
                  class="pl-4 leading-10 font-bold text-sm transition-colors ease-in-out duration-200"
                  :class="getClassesForTableName(table)"
                >
                  {{ table.name }}
                </span>
                <div @click.stop v-if="table.is_separately" class="h-full mr-2">
                  <el-dropdown
                    trigger="click"
                    @command="(command) => downloadSeparately(table, command)"
                  >
                    <span>
                      <svg
                        class="download-separatly el-dropdown-link"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 10.4951V13.299C2 13.6862 2.33579 14 2.75 14H13.25C13.6642 14 14 13.6862 14 13.299V10.4951M8 1V10.1127M8 10.1127L11 7.30882M8 10.1127L5 7.30882"
                          stroke="#4E77A7"
                          stroke-width="1.5"
                          stroke-miterlimit="3.8637"
                          stroke-linecap="round"
                        />
                      </svg>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item command="excel"
                          >{{ $t('save') }} Excel</el-dropdown-item
                        >
                        <el-dropdown-item command="word"
                          >{{ $t('save') }} Word</el-dropdown-item
                        >
                        <el-dropdown-item command="pdf"
                          >{{ $t('save') }} PDF</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
                <!-- <a
                  class="h-full"
                  style="margin-right: 6px"
                  @click.stop="downloadSeparately(table)"
                  v-if="table.is_separately"
                  :href="separetedReportLink"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 10.4951V13.299C2 13.6862 2.33579 14 2.75 14H13.25C13.6642 14 14 13.6862 14 13.299V10.4951M8 1V10.1127M8 10.1127L11 7.30882M8 10.1127L5 7.30882"
                      stroke="#4E77A7"
                      stroke-width="1.5"
                      stroke-miterlimit="3.8637"
                      stroke-linecap="round"
                    />
                  </svg>
                </a> -->
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ComposeReport
      v-if="isComposedReport"
      :isReport="isReport"
      :formData="reportComposeData"
      @handlePrint="handlePrint"
      @close="isComposedReport = false"
      :tableId="reportForm.tableType"
    />
  </div>
</template>

<script>
import leafletImage from 'leaflet-image'
import axios from 'axios'
import moment from 'moment-timezone'
import mapForReports from '@/mixins/mapForReports'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'Reports',
  components: {
    ComposeReport: () => import('./ComposeReport.vue'),
    LoadIcon: () => import('@/components/reports/icons/LoadIcon.vue'),
    MapPointIcon: () => import('@/components/reports/icons/MapPointIcon.vue'),
    AddIcon: () => import('@/components/reports/icons/AddIconNoBg.vue'),
    EditIcon: () => import('@/components/reports/icons/Edit.vue'),
    CancelBigIcon: () => import('@/components/reports/icons/CancelBigIcon.vue'),
    InfoIconBlue: () => import('@/components/reports/icons/InfoIconBlue.vue')
  },
  mixins: [mapForReports],
  props: {
    monitoringunit: {
      type: Object,
      default: () => {}
    },
    map: {
      type: Object,
      default: () => {}
    },
    fromMonitoring: {
      type: Boolean,
      default: false
    },
    isComposeReport: {
      type: Boolean,
      default: false
    },
    isReport: {
      type: Boolean,
      default: false
    },
    isRefreshReport: {
      type: Boolean,
      default: false
    },
    isDemo: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      chartIndex: 0,
      defaultTimeValues: ['00:00:00', '00:00:00'],
      isComposedReport: false,
      company_id: '',
      user_id: '',
      isOnlyUnits: false,
      isFilterExist: false,
      showAllUnits: false,
      isShowAllUnits: false,
      currentFilters: [],
      filtredUnits: [],
      isFilterMonitoring: false,
      centerDialogVisible: false,
      isAll: false,
      reportForm: {
        tableType: '',
        object: 'base',
        unitsId: '',
        datePicker: ['', '']
        // unitsName: ''
      },
      reportErrors: [],
      isUnitOrUnitsgroupMissing: false,
      dateFormat: '',
      timeFormat: '',
      dateTimeFormat: '',
      company: '',
      printTables: [],
      printTablesObj: {},
      printAllTables: false,
      customUnits: null,
      filterUnits: null,
      tracksdone: null,
      separetedReportLink: null,
      objectDeleteTemplate: {},
      reportComposeData: {},
      isPeriodSet: false,
      reportString: ''
    }
  },

  computed: {
    ...mapState('reports', {
      reportTemplates: (state) => state.templates,
      currentReport: (state) => state.currentReport,
      drawenArrows: (state) => state.drawenArrows,
      drawenMakers: (state) => state.drawenMakers,
      mapToUse: (state) => state.mapToUse,
      chartBase64: (state) => state.chartBase64,
      recoveryId: (state) => state.recoveryId,
      newChartPeriod: (state) => state.isPeriodSet
    }),
    ...mapGetters('login', [
      'activeCompanyId',
      'changingCompany',
      'role',
      'me'
    ]),

    ...mapGetters({
      groupTrackReport: 'reports/groupTrackReport',
      defaultReportTemplate: 'reports/defaultReportTemplate',
      formatTime: 'company/formatTime'
    }),
    ...mapState('units', {
      units: (state) => state.units,
      unitGroups: (state) => state.unitsGroups,
      defaultUnits: (state) => state.defaultUnits
    }),
    ...mapState('login', {
      me: (state) => state.me,
      formattedCurrentTime: (state) => state.formattedCurrentTime
    }),
    // customUnits() {

    //   return this.showAllUnits ? this.defaultUnits : this.filtredUnits
    // },
    sortedUnits() {
      if (this.customUnits) {
        return this.customUnits.slice().sort((a, b) => {
          const nameA = a.name.toLowerCase().trim()
          const nameB = b.name.toLowerCase().trim()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        })
      }
      return []
    },

    printIndeterminate() {
      const countChecks = this.printTables.length

      const all = this.tablesForTemplate.length
      return !!(countChecks && countChecks !== all)
    },
    tablesForTemplate() {
      const tables = this.reportTemplates.find(
        (item) => item.id === this.reportForm.tableType
      )

      const basicTable = this.defaultReportTemplate

      return tables ? tables.template_tables : basicTable.template_tables
    },
    reportTables() {
      return this.$parent.reportTables
    },
    printLink() {
      if (this.printTables.length === 0) {
        return false
      }

      const params = {
        template_tables: this.printTables.map((item) => ({ id: item })),
        from: this.reportForm.datePicker
          ? this.reportForm.datePicker[0]
          : undefined,
        to: this.reportForm.datePicker
          ? this.reportForm.datePicker[1]
          : undefined
      }

      const key = this.reportForm.object === 'group' ? 'groups' : 'units'
      params[key] = [{ id: this.reportForm.unitsId }]

      return params
    },
    last24Hours() {
      const from = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
      const to = moment()
        .add(1, 'days')
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss')
      return {
        from,
        to
      }
    },
    isFromMonitoringOption() {
      const filters = JSON.parse(
        localStorage.getItem(`filtredUnits_${this.company_id}`)
      )

      return (
        filters && filters.filter && this.reportForm.object === 'monitoring'
      )
    },
    filteredTablesForTemplate() {
      return this.tablesForTemplate.filter((table) =>
        table.name.toLowerCase().includes(this.reportString.toLowerCase())
      )
    },
    pickerOptions() {
      return {
        firstDayOfWeek: 1
      }
    }
  },

  watch: {
    reportTemplates(val) {
      if (val.length === 2) {
        this.reportForm.tableType = val[1].id
      } else if (val.length === 1) {
        this.reportForm.tableType = val[0].id
      }
    },
    'reportForm.tableType': {
      handler(newValue) {
        if (
          this.reportTemplates.length > 1 &&
          newValue === this.defaultReportTemplate.id
        ) {
          this.reportForm.tableType = this.reportTemplates[1].id
          this.$forceUpdate()
        }
      }
    }
  },

  methods: {
    handlerFocus() {
      const datepicker = this.$refs.datePickerRef
      if (datepicker) {
        setTimeout(() => {
          const reportsDatepicker = document.querySelector('.reportsDatepicker')
          reportsDatepicker.addEventListener('click', this.handler)
          document.addEventListener('keydown', this.handlerEnter)
        }, 500)
      }
      this.preserveTime()
    },

    handlerBlur() {
      this.preserveTime()
    },
    dateChange(value) {
      this.preserveTime()
      this.isPeriodSet = false
      this.reportForm.datePicker = value
      this.preserveTime()
      const anotherFormat = '00:00:00'

      localStorage.setItem(
        `reportsForm${this.user_id}${this.company_id}`,
        JSON.stringify(this.reportForm)
      )

      const formattedTime = JSON.parse(localStorage.getItem('formattedTime'))

      if (formattedTime === '23:59:59' && value[1].includes(anotherFormat)) {
        const endDate = this.reportForm.datePicker[1]
        const momentDate = moment(endDate, 'YYYY-MM-DD HH:mm:ss')
        momentDate.set({ hour: 23, minute: 59, second: 59 })
        this.reportForm.datePicker[1] = momentDate.format('YYYY-MM-DD HH:mm:ss')

        localStorage.setItem(
          `reportsForm${this.user_id}${this.company_id}`,
          JSON.stringify(this.reportForm)
        )
      }
      // this.preserveTime()
      // this.cacheReportForm()
    },
    preserveTime() {
      const fullArrTime = this.reportForm.datePicker.map((item) => {
        const currentTimeArr = item.split(' ')
        return currentTimeArr[1] // Extract the time part
      })
      this.defaultTimeValues = fullArrTime // Update default times

      localStorage.setItem(
        `reportsForm${this.user_id}${this.company_id}`,
        JSON.stringify(this.reportForm)
      )
    },

    handler(event) {
      if (event.key === 'Enter') {
        const datepicker = this.$refs.datePickerRef
        datepicker.handleClose()
      }
    },
    handlerEnter(e) {
      if (e.key === 'Enter') {
        const btn = document.querySelector('.el-button--mini.is-plain')
        const event = new Event('click')
        btn.dispatchEvent(event)
      }
    },
    searching(val) {
      this.reportString = val
    },

    setPeriod(period) {
      // Determine the current date based on this.formatTime
      let now = moment().endOf('day')
      if (this.formatTime === '00:00:00') {
        now = now.startOf('day').add(1, 'day')
      }

      let startDate
      let endDate

      switch (period) {
        case 'today':
          startDate = moment().startOf('day')
          endDate = now.format('YYYY-MM-DD HH:mm:ss')
          break
        case 'yesterday':
          startDate = moment().subtract(1, 'days').startOf('day')
          if (this.formatTime === '00:00:00') {
            endDate = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
          } else {
            endDate = moment()
              .subtract(1, 'days')
              .endOf('day')
              .format('YYYY-MM-DD HH:mm:ss')
          }
          break
        case 'this_week':
          startDate = moment().startOf('isoWeek')
          endDate = now.format('YYYY-MM-DD HH:mm:ss')
          break
        case 'this_month':
          startDate = moment().startOf('month')
          endDate = now.format('YYYY-MM-DD HH:mm:ss')
          break
        default:
          throw new Error(`Invalid period: ${period}`)
      }

      const formattedStartDate = startDate.format('YYYY-MM-DD 00:00:00')
      const formattedEndDate = endDate

      this.reportForm.datePicker = [formattedStartDate, formattedEndDate]
    },
    shouldShowOption(template) {
      return (
        (this.reportTemplates.length > 0 && !template.is_default) ||
        (this.reportTemplates.length === 1 && template.is_default)
      )
    },
    doImage() {
      const map = this.$parent.$refs['leaflet-map']

      const component = this
      if (component.drawenMakers && component.drawenMakers.length > 0) {
        Object.keys(component.drawenMakers).forEach((marker) => {
          const drawenMarker = component.drawenMakers[marker]
          if (map.map.hasLayer(drawenMarker)) {
            map.map.removeLayer(drawenMarker)
            setTimeout(() => {
              map.map.addLayer(drawenMarker)
            }, 1000)
          }
        })
      }
      if (component.drawenArrows && component.drawenArrows.reports) {
        component.drawenArrows.reports.eachLayer((arrow) => {
          arrow.removeFrom(map.map)
          setTimeout(() => {
            arrow.removeFrom(map.map)
          }, 1000)
        })
      }

      return new Promise((resolve, reject) => {
        leafletImage(map.map, (err, canvas) => {
          if (err) reject(err)
          canvas.toBlob((blob) => {
            const reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onloadend = () => resolve(reader.result.split(',')[1])
          })
        })
      })
    },
    async handlePrint(params) {
      try {
        const mapIimageBase64 = await this.doImage()
        const formData = {}
        const files = []
        if (params.isPrintMap) {
          files.push(mapIimageBase64)
        }
        if (params.isChart) {
          const imgUri = this.chartBase64.imgURI
          const chartBase64Data = imgUri.replace(
            /^data:image\/(png|jpeg|jpg);base64,/,
            ''
          )
          files.push(chartBase64Data)
        }
        formData.files = files
        if (params.formData.units) {
          formData.units = params.formData.units
        }
        if (params.formData.groups) {
          formData.groups = params.formData.groups
        }
        formData.template_tables = params.formData.template_tables
        formData.from = params.formData.from
        formData.to = params.formData.to
        this.downloadReports(params.format, formData)
      } catch (err) {
        console.error(err)
      }
    },

    ...mapActions({
      getFastGroupReport: 'reports/getFastTrackGroupsReport'
    }),
    ...mapMutations('units', ['SHOW_UNITSGROUP']),
    ...mapMutations('reports', [
      'SHOW_REPORT_CHART',
      'CLOSED_CALL_DRAW',
      'setTableType',
      'SET_TRACK'
    ]),
    openDialogDeleteTemplate(template) {
      this.centerDialogVisible = true
      this.objectDeleteTemplate = template
    },
    async downloadSeparately(table, type) {
      const params = {
        template_tables: [{ id: table.id }],
        from: this.reportForm.datePicker[0],
        to: this.reportForm.datePicker[1]
      }
      params[this.reportForm.object === 'group' ? 'groups' : 'units'] = [
        { id: this.reportForm.unitsId }
      ]
      const link = `/api_v1/report_${type}`
      axios
        .post(link, params, {
          responseType: 'blob'
        })
        .then((response) => {
          // create a URL object from the response data
          const url = window.URL.createObjectURL(new Blob([response.data]))

          const disposition = response.headers['content-disposition']
          const matches = /filename=([^;]+)/gi.exec(disposition)
          const filename = (matches[1] || 'all_reports').trim()

          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()

          link.parentNode.removeChild(link)
          window.URL.revokeObjectURL(url)
        })
        .catch(async (error) => {
          const responseObj = await error.response.data.text()
          const errorMessage = JSON.parse(responseObj)
          this.$notify.error({
            duration: 5000,
            title: `${this.$t('error')} \u{1f62a}`,
            message: errorMessage.message
          })
        })
    },

    handleClose() {
      this.reportForm.isFromMonitoring = false
      this.$emit('close')
    },
    toggleAllUnits() {
      if (this.showAllUnits) {
        this.customUnits = this.defaultUnits
      } else {
        this.customUnits = this.filterUnits
      }
      this.reportForm.unitsId = this.customUnits[0].id
    },
    printFilterTable(table) {
      return !(
        table.key &&
        ['chart', 'notifications', 'journal', 'messages'].includes(table.key)
      )
    },
    downloadAllReports(type) {
      if (type === 'composed') {
        const currentUnits = this.units.filter((unit) => {
          if (this.printLink.units) {
            this.printLink.units.some((item) => item.id === unit.id)
          } else {
            this.printLink.groups.some((item) => item.id === unit.id)
          }
        })
        const currentTablesTemplate = this.printLink.template_tables
        const currenttables = this.tablesForTemplate.filter((tableTemplate) =>
          currentTablesTemplate.some((item) => item.id === tableTemplate.id)
        )

        // get the data from th ecomponent then ask for a report with a print
        this.reportComposeData = {
          currentUnits,
          currenttables,
          ...this.printLink
        }
        this.isComposedReport = true
      } else {
        this.downloadReports(type, this.printLink)
      }
    },
    downloadReports(type, formData) {
      const link = `/api_v1/report_${type}`
      axios
        .post(link, formData, {
          responseType: 'blob'
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))

          const disposition = response.headers['content-disposition']
          const matches = /filename=([^;]+)/gi.exec(disposition)
          const filename = (matches[1] || 'all_reports').trim()

          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${filename}`)
          document.body.appendChild(link)
          link.click()

          link.parentNode.removeChild(link)
          window.URL.revokeObjectURL(url)
        })
        .catch(async (error) => {
          const responseObj = await error.response.data.text()
          const errorMessage = JSON.parse(responseObj)
          this.$notify.error({
            duration: 5000,
            title: `${this.$t('error')} \u{1f62a}`,
            message: errorMessage.message
          })
        })
    },
    resetPrints() {
      this.printTables = []
      this.printTablesObj = {}
      this.printAllTables = false
    },
    togglePrint(key, $event) {
      if ($event.target.checked) {
        this.printTables.push(key)
      } else {
        this.printTables = this.printTables.filter((item) => item !== key)
      }
    },
    togglePrintAll() {
      this.printTables = []
      const all = this.printAllTables
      this.tablesForTemplate.forEach((table) => {
        if (this.printFilterTable(table)) {
          this.printTablesObj[table.id] = all
          if (all) {
            this.printTables.push(table.id)
          }
        }
      })
    },
    objectChange($event) {
      if (this.defaultUnits.length) {
        this.isUnitOrUnitsgroupMissing = false
        if ($event.target.value === 'base' && this.isFilterExist) {
          this.isFilterMonitoring = false
          this.isOnlyUnits = true
          if (this.showAllUnits) {
            this.reportForm.unitsId = this.defaultUnits[0].id
          } else {
            this.reportForm.unitsId = this.customUnits[0].id
          }
          // this.reportForm.unitsId = this.units.length ? this.units[0].id : ''
        } else if ($event.target.value === 'base') {
          this.reportForm.unitsId = this.defaultUnits[0].id
          this.isFilterMonitoring = false
        } else if ($event.target.value === 'group') {
          this.isShowAllUnits = false
          this.isFilterMonitoring = false
          this.isOnlyUnits = false
          this.reportForm.unitsId = this.unitGroups.length
            ? this.unitGroups[0].id
            : ''
          this.groupUnits = true
        } else if ($event.target.value === 'monitoring') {
          this.isFilterMonitoring = true
          this.isShowAllUnits = false
        }
      }
      // this.cacheReportForm()
    },
    tableTypeChange() {
      this.resetPrints()
      localStorage.setItem(
        `reportsForm${this.user_id}${this.company_id}`,
        JSON.stringify(this.reportForm)
      )

      // this.cacheReportForm()
    },
    unitsNameChange(unit) {
      this.reportForm.unitsId = unit
      localStorage.setItem(
        `reportsForm${this.user_id}${this.company_id}`,
        JSON.stringify(this.reportForm)
      )

      // this.cacheReportForm()
    },

    cacheReportForm() {
      if (process.env.NODE_ENV === 'production') {
        let storageReportForm = localStorage.getItem(
          `reportsForm${this.user_id}${this.company_id}`
        )

        storageReportForm = storageReportForm
          ? JSON.parse(storageReportForm)
          : {}
        storageReportForm[this.activeCompanyId] = this.reportForm

        localStorage.setItem(
          `reportsForm${this.user_id}${this.company_id}`,
          JSON.stringify(storageReportForm)
        )
      }
    },

    async showReport(item) {

      let openedReports = JSON.parse(localStorage.getItem('openedReports'))
      const filters = JSON.parse(
        localStorage.getItem(`filtredUnits_${this.company_id}`)
      )

      if (!openedReports) {
        openedReports = []
      }

      const currentUnits = this.units.filter(
        (unit) => this.reportForm.unitsId === unit.id
      )
      if (item && item.key && !openedReports.includes(item.key)) {
        openedReports.push({
          name: item.name,
          key: item.key,
          unit: currentUnits[0],
          id: item.id,
          time_group: item.time_group || '',
          closed_to_change: item.closed_to_change
        })
      }
      localStorage.setItem('openedReports', JSON.stringify(openedReports))

      this.setTableType(item.key)

      const isGroupObject = this.reportForm.object === 'group'
      const isBaseObject = this.reportForm.object === 'base'
      const isMonitoringObject = this.reportForm.object === 'monitoring'
      const isChart = item.key === 'chart'

      if (isGroupObject && isChart) return

      if (isGroupObject && item.key === 'fields_processing') {
        // unitsId - id группы
        const dataReport = {
          groups: [{ id: this.reportForm.unitsId }],
          from: this.reportForm.datePicker[0],
          to: this.reportForm.datePicker[1],
          zoom: 14
        }
        // рисуем групповой трек на карте
        await this.getFastGroupReport(dataReport)
          .then((response) => {
            const { data } = response
            const allTrack = data.reduce(
              (acc, curr) => acc.concat(curr.values),
              []
            )
            const unitId = dataReport.groups[0].id
            const uniqueTrackId = `[${unitId}][${dataReport.from}-${dataReport.to}]`

            // записываем трек через в мутацию
            // в mapForMonitoring есть watch - polyline
            this.SET_TRACK([unitId, allTrack, uniqueTrackId])
          })

          .catch((error) => {
            console.log(error)
          })
      }

      if (!isChart) {
        this.SHOW_REPORT_CHART()
      }

      if (isBaseObject || isMonitoringObject) {
        const unitIds = []
        this.customUnits.forEach((unit) => {
          unitIds.push({ id: unit.id })
        })

        if (this.isFromMonitoringOption) {
          this.reportForm.unitsId = unitIds
        }
        this.$store.dispatch('units/SHOW_UNITS_GROUP', {
          id: this.isFromMonitoringOption
            ? this.reportForm.unitsId
            : [{ id: this.reportForm.unitsId }],
          show: false
        })
      } else if (isGroupObject) {
        this.$store.dispatch('units/SHOW_UNITS_GROUP', {
          id: this.reportForm.unitsId,
          show: true
        })
      }

      const reportId = isChart
        ? item.id
        : item.id +
          (this.isFromMonitoringOption
            ? this.reportForm.unitsId[0].id
            : this.reportForm.unitsId) +
          this.reportForm.datePicker.join('')

      if (this.reportForm.unitsId === '') {
        this.isUnitOrUnitsgroupMissing = this.reportForm.unitsId === ''
        this.reportErrors.push(reportId)
        setTimeout(() => {
          this.reportErrors.splice(this.reportErrors.indexOf(reportId), 1)
        }, 500)
        return
      }

      if (
        this.reportTables.some((x) => x.reportId === reportId) ||
        this.reportTables.length > 3
      ) {
        this.reportErrors.push(reportId)
        setTimeout(() => {
          this.reportErrors.splice(this.reportErrors.indexOf(reportId), 1)
        }, 500)
      } else {
        this.CLOSED_CALL_DRAW(false)
        let from = ''
        let to = ''
        if (item.key === 'messages') {
          from = this.last24Hours.from
          to = this.last24Hours.to
        } else {
          from = this.reportForm.datePicker[0]
          to = this.reportForm.datePicker[1]
        }
        this.$nextTick(() => {
          if (isBaseObject || isMonitoringObject) {
            this.$store.dispatch('reports/GET_TRACK', {
              zoom: 14,
              units: this.isFromMonitoringOption
                ? filters.units.map((item) => ({ id: item }))
                : [{ id: this.reportForm.unitsId }],
              from,
              to
            })
          }
        })

        item.tableType = item.key === 'chart' ? 'reportChart' : 'reportTable'

        this.$store.commit('reports/SET_LOADING', [reportId, true])

        const itemOrGroup =
          isBaseObject || isMonitoringObject ? 'units' : 'groups'
        const idType = this.isFromMonitoringOption
          ? filters.units.map((item) => ({ id: item }))
          : [{ id: this.reportForm.unitsId }]
        const baseFormData = {
          [itemOrGroup]: idType,
          template_tables: [{ id: item.id }],
          from: this.reportForm.datePicker[0],
          to: this.reportForm.datePicker[1]
        }

        if (this.isAll && item.key === 'notifications') {
          this.$emit('show-report', {
            reportFormData: {
              ...baseFormData,
              isAll: this.isAll,
              time_group: item.time_group || '',
              tableId: item.id,
              closed_to_change: item.closed_to_change
            },
            tableInfo: { ...item, reportId },
            isZoomed: false
          })
        } else {
          this.$emit('show-report', {
            reportFormData: {
              ...baseFormData,
              time_group: item.time_group || '',
              tableId: item.id,
              closed_to_change: item.closed_to_change
            },
            tableInfo: { ...item, reportId },
            isZoomed: false
          })
        }
      }

      const canv = document.getElementsByTagName('canvas')

      if (canv.length > 1) {
        if (navigator.userAgent.indexOf('Chrome') !== -1) {
          canv[0].style.zIndex = '101'
          canv[1].style.zIndex = '100'
        }
        if (navigator.userAgent.indexOf('Firefox') !== -1) {
          canv[0].style.zIndex = '101'
          canv[1].style.zIndex = '100'
        }
      }
    },

    defaultValueDate() {
      const formattedTime = JSON.parse(localStorage.getItem('formattedTime'))
      let startDate = ''
      let endDate = ''
      if (formattedTime === '23:59:59') {
        startDate = moment()
          .set({ hour: 0, minute: 0, second: 0 })
          .format('YYYY-MM-DD HH:mm:ss')
        endDate = moment(startDate)
          .set({ hour: 23, minute: 59, second: 59 })
          .format('YYYY-MM-DD HH:mm:ss')

        return [startDate, endDate]
      }
      startDate = moment()
        .set({ hour: 0, minute: 0, second: 0 })
        .format('YYYY-MM-DD HH:mm:ss')
      endDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD HH:mm:ss')
      return [startDate, endDate]
    },
    onPaste(event) {
      const datesMatch = event.clipboardData
        .getData('text')
        .match(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}/g)
      const dates = [`${datesMatch[0]}:00`, `${datesMatch[1]}:00`]
      if (
        dates.length === 2 &&
        moment(dates[0], 'yyyy-MM-dd HH:mm:ss').isValid() &&
        moment(dates[1], 'yyyy-MM-dd HH:mm:ss').isValid()
      ) {
        this.reportForm.datePicker = dates
      }
    },
    // tablesForTemplate
    deleteTemplate(template) {
      this.centerDialogVisible = false
      this.$store.dispatch('reports/DELETE_TEMPLATE', template).then(() => {
        // remove template recovery for now !
        // this.showDeleteNotification(template)
        if (template.id === this.reportForm.tableType) {
          const remainingTemplates = this.reportTemplates.filter(
            (t) => t.id !== template.id
          )
          if (remainingTemplates.length === 1) {
            this.reportForm.tableType = this.reportTemplates[0]

            localStorage.setItem(
              `reportsForm${this.user_id}${this.company_id}`,
              JSON.stringify(this.reportForm)
            )
          } else if (remainingTemplates.length >= 2) {
            const deletedIndex = this.reportTemplates.findIndex(
              (t) => t.id === template.id
            )

            const nextIndex =
              deletedIndex === this.reportTemplates.length - 1
                ? deletedIndex - 1
                : deletedIndex

            this.reportForm.tableType = remainingTemplates[nextIndex].id
          }
        }
      })
    },
    showDeleteNotification(template) {
      const self = this
      this.$notify({
        dangerouslyUseHTMLString: true,
        message: `<div class='confirmation-container'>
                <span>${this.$t('reports.template')} ${
          template.name
        }  ${this.$t('reports.was-deleted')} </span>
                <span id='confirm' class='cursor-pointer cancelConfirm blue-text'>${this.$t(
                  'cancel'
                )}</span>
              </div>`,
        duration: 10000,
        position: 'bottom-left',
        customClass: 'confirmation  '
      })

      const confirmation = document.getElementById('confirm')
      confirmation.addEventListener(
        'click',
        function () {
          self.restoreTemplate(template)
        },
        false
      )
    },
    restoreTemplate(template) {
      this.$store.dispatch('reports/RECOVER_TEMPLATE', {
        id: this.recoveryId,
        template
      })
      this.$notify.closeAll()
    },
    setFormLocalStorage(result) {
      let storageReportForm = localStorage.getItem(
        `reportsForm${this.user_id}${this.company_id}`
      )

      storageReportForm = storageReportForm
        ? JSON.parse(
            localStorage.getItem(`reportsForm${this.user_id}${this.company_id}`)
          )
        : {}
      if (storageReportForm[this.activeCompanyId]) {
        const form = storageReportForm[this.activeCompanyId]
        const isRealId =
          (result[1].some((item) => item.id === form.unitsId) &&
            form.object === 'base') ||
          (result[2].some((item) => item.id === form.unitsId) &&
            form.object === 'group')
        const isRealRTId = result[0].some((rt) => rt.id === form.tableType)
        this.reportForm = {
          tableType: isRealRTId ? form.tableType : '',
          object: form.object,
          unitsId: isRealId ? form.unitsId : '',
          datePicker: form.datePicker
        }
      } else {
        storageReportForm[this.activeCompanyId] = this.reportForm = {
          tableType: result[0][0].id,
          object: 'base',
          unitsId: result[1].length ? result[1][0].id : '',
          datePicker: this.defaultValueDate()
        }

        localStorage.setItem(
          `reportsForm${this.user_id}${this.company_id}`,
          JSON.stringify(storageReportForm)
        )
      }
    },
    isUnitExist(unit, array) {
      return array.some((element) => element.id === unit)
    },

    getClassesForTableName(table) {
      const hasError = this.reportErrors.includes(
        table.id + this.reportForm.unitsId + this.reportForm.datePicker.join('')
      )
      const disableChart =
        this.reportForm.object === 'group' && table.key === 'chart'

      return {
        'text-notify': hasError,
        'text-headerText': !hasError,
        disabled: disableChart
      }
    },
    setDefaultUnit() {
      this.reportForm.unitsId = this.defaultUnits.length
        ? this.defaultUnits[0].id
        : ''
    },
    setDefaultTableType() {
      if (this.currentReport && this.currentReport.tableType) {
        this.reportForm.tableType = this.defaultReportTemplate.id
        console.log(1, this.reportTemplates)
        console.log(2, this.currentReport.tableType)
        return
      }

      this.reportForm.tableType = this.reportTemplates.length
        ? this.reportTemplates[0].id
        : ''
    },
    getDateFormat() {
      setTimeout(() => {
        const company = this.me.active_company
        const formattedTime = JSON.parse(localStorage.getItem('formattedTime'))

        if (formattedTime) {
          this.dateFormat = `${company.dateformat.key} ${formattedTime}`
        } else {
          this.dateFormat = company.dateformat.key
        }
        this.dateFormat = company.dateformat.key
        this.timeFormat = company.timeformat.key

        this.dateTimeFormat = `${this.dateFormat} ${this.timeFormat}`
      }, 100)
    },
    checkExistingUnit(unitId, type = 'base') {
      const units = type === 'base' ? this.customUnits : this.unitGroups
      const isUnitExist = units.find((item) => item.id === unitId)

      if (!isUnitExist) this.reportForm.unitsId = units[0].id
    }
  },

  created() {
    this.setDefaultUnit()
    this.setDefaultTableType()
  },
  mounted() {
    this.user_id = this.me.id
    this.company_id = this.me.active_company.id

    const filters = JSON.parse(
      localStorage.getItem(`filtredUnits_${this.company_id}`)
    )
    const reportForm = JSON.parse(
      localStorage.getItem(`reportsForm${this.user_id}${this.company_id}`)
    )

    const reportFormStorage = reportForm || {}
    this.reportForm = {
      ...this.reportForm,
      ...reportFormStorage
    }
    this.customUnits = this.defaultUnits

    const isFilterExist = !!filters && !!filters.filter

    if (!isFilterExist && this.reportForm.object === 'monitoring')
      this.reportForm.object = 'base'

    if (this.fromMonitoring && isFilterExist)
      this.reportForm.object = 'monitoring'

    this.checkExistingUnit(this.reportForm.unitsId, this.reportForm.object)

    // CHECK FILTERS
    if (isFilterExist) {
      this.showAllUnits = this.reportForm.showAllObjects

      this.customUnits = this.filterUnits = this.units.filter((unit) =>
        filters.units.includes(unit.id)
      )

      if (this.showAllUnits) this.customUnits = this.defaultUnits

      if (this.reportForm.object === 'base') {
        this.checkExistingUnit(this.reportForm.unitsId)

        this.isOnlyUnits = true
      }

      this.isFilterExist = true
    }

    // CHECK DATEPICKER EXISTING IN STORAGE
    const currentDateLocalStorage = JSON.parse(
      localStorage.getItem('currentDateReport')
    )

    const currentDateNumber = new Date()
    const numberDate = currentDateNumber.getDate()

    if (!currentDateLocalStorage) {
      localStorage.setItem('currentDateReport', numberDate)
    }

    if (currentDateLocalStorage !== numberDate) {
      this.reportForm.datePicker = this.defaultValueDate()
      localStorage.setItem('currentDateReport', numberDate)
    }

    if (reportFormStorage && !reportFormStorage.datePicker) {
      this.reportForm.datePicker = this.defaultValueDate()
    }

    // CHECK TABLE TYPE
    if (reportFormStorage && !reportFormStorage.tableType) {
      this.setDefaultTableType()
    }

    this.getDateFormat()

    if (this.newChartPeriod.length) {
      this.reportForm.datePicker = this.newChartPeriod
      this.isPeriodSet = true
    } else {
      this.isPeriodSet = false
    }

    /**
     * For demo only
     * */
    if(this.isDemo) {
      setTimeout(() => {
        this.filteredTablesForTemplate.forEach((item) => {
          if(item.name.toLowerCase() === 'поездки') {
            this.reportForm.unitsId = '6d3892de-421a-4f0e-bc13-330534fc3381';
            this.reportForm.datePicker = ["2024-04-01 00:00:00", "2024-04-14 00:00:00"]
            this.showReport(item);
          }
        })
      }, 1000)
    }


  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handler)
    this.$store.dispatch('reports/SAVE_CURRENTREPORT', this.reportForm)
    this.$emit('clear-monitoringunit')

    localStorage.setItem(
      `reportsForm${this.user_id}${this.company_id}`,
      JSON.stringify({
        ...this.reportForm,
        showAllObjects: this.showAllUnits,
        groupUnit:
          this.reportForm.object === 'group' ? this.reportForm.unitsId : ''
      })
    )
  }
}
</script>
<style>
.searching-container {
  position: sticky;
  top: 45px;
}
.lighter-border-b {
  border-bottom: 1px solid #f6f7fa;
}
.info-popoverlay {
  padding: 0;
  background: #dbebff;
  width: auto !important;

  padding: 4px 8px;
  overflow: hidden;
}
.info-container {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: #2767b6;
}
.period {
  align-items: center;
  text-align: center;
  font-family: Source Sans Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}
.period-item {
  color: #20579a;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background: #dbebff;
  border-radius: 3px;
}
.period-item:hover {
  color: #fff;
  cursor: pointer;
  background: #3a7cca;
}
.blue-text {
  color: #80abe1;
}
.bg-select-object {
  background-color: #f6f7fa;
}
.select-object-container {
  padding-left: 14px;
  padding-top: 11px;
  padding-bottom: 12px;
  border-radius: 4px;
}
.confirmation {
  width: 466px;
  background-color: #eff4fb;
  margin-left: 4.5rem;

  .el-notification__group {
    margin-right: 30px;
    width: 95%;

    .el-notification__content {
      width: 95%;

      p {
        .confirmation-container {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .el-icon-close {
      margin-top: 3px;

      &::before {
        font-weight: bolder;
        font-size: 18px;
      }
    }
  }
}
.slide-fade-enter-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>
<style lang="stylus" scoped>

.download-separatly{
  outline:none
}
.boldText {
  font-size: 16px;
  font-weight: bolder;
}

.delete_template {
  box-shadow: none !important;
}

.skif-load-link {
  display flex
  font-size: 14px;
  color: #5477A9;

  svg {
    width: 20px;
    height: 20px;
  }
}

.disabled {
  opacity: 0.5;
  cursor: default;
}

.tooltip-warning {
  left: 345px;
  border: 1px solid #cfdbeb;
  bottom: 25px;
  width: 320px;
  border-radius: 5px;
}
.group-name {
  width: 240px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.row-reverse {
  flex-direction: row-reverse;
}
</style>
